<template>
  <div class="evo-mtc-wrapper evo-ph-176 evo-pv-60">
    <div class="section-1-half-bg"></div>

    <div class="evo-head text-center">
      <div data-aos="slide-up"
      data-aos-delay="100"
      data-aos-duration="1000" class="evo-text-51 evo-mb-23 evo-yu-gothic-light">{{ $t("mtc.title") }}</div>
      <div data-aos="slide-up"
      data-aos-delay="100"
      data-aos-duration="1000" class="evo-text-22 evo-mb-130 evo-yu-gothic-light">
        {{ $t("mtc.desc") }}
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "EvoMTCIndexSection",
};
</script>
<style lang="scss" scoped>
.evo-mtc-wrapper {
  background: $header_bg_color;
  position: relative;
  .evo-head {
    position: relative;
    height: calc(60vh - 60px);
    color: $header_color;
  }
  .section-1-half-bg {
    background-color: $nav_color;
  }
}
</style>
