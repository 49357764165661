<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMMTCIndexSection />
    </template>
    <template v-else>
      <EvoMTCIndexSection />
    </template>
  </div>
</template>
<script>
import EvoMTCIndexSection from '@/components/mtc_sections/index.vue';
import EvoMMTCIndexSection from '@/mobile/components/mtc_sections/MIndex.vue';
export default {
  name: 'EvoMTCIndexView',
  components: {
    EvoMTCIndexSection,
    EvoMMTCIndexSection
  }
};
</script>